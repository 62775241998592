.panel-candidate-details {
  .user-img {
    float: left;
    margin-right: 40px;
  }
  .candidate-credential {
    padding: 5px;
    line-height: 2.5rem;
    border-bottom: 1px solid #eee;
  }
}

form {
  .candidate-credential-fields {
    padding: 5px 0;
  }
}

.candidates-nav {
  margin-bottom: 15px;
}

.attachments {
  margin-top: 10px;
  font-size: 1.3rem;
}

.candidate-calendar {
  height: 360px;
  td {
    border: 1px solid #eee !important;
    &.selected {
      background-color: $link-color !important;
      color: #ffffff;
    }
    .day {
      height: 35px;
      padding-top: 0px !important;
      .data {
        .preference { font-size: 1.1rem; }
        .shift {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}

.panel-shift-preferences td:first-child { width: 140px; }

.candidate-preferences {
  background-color: #E2F9FF !important;
  color: #000000 !important;
  label { color: #000000 !important; }
}
