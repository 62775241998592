$clouds: #ecf0f1;
$link-color: #2F9BDE;
$link-color-hover: #2B8FCF;
$emerald: #2ecc71;
$orange: #f39c12;
$pomegranate: #c0392b;
$silver: #bdc3c7;

.popover-content {
  .big-popover {
    min-height: 100px;
    max-height: 350px;
    width: $popover-max-width - 30px; // subtract padding
    line-height: 100px;
    .spinner-container {
      text-align: center;
      i {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    }
  }
  .content {
    overflow-y: scroll;
    min-height: 50px;
    max-height: 350px;
  }
}

.fa-spinner { color: #979898; }

.reveal .fa {
  font-family: 'FontAwesome';
  font-style: normal;
}

.link{
  color: $link-color;
  cursor: hand;
  cursor: pointer;
}

.margin-bottom{
  margin-bottom: 40px;
}
.hand-cursor{
  cursor: hand;
  cursor: pointer;
}


.strong {
  font-weight: 700;
}

tr.separator {
  border-top: 1px dashed #eee;
}

table.list tr {
  td{
    line-height:2.5rem;;
  }
  td:first-child {
    font-weight: 700;
    padding-right: 0px;
    max-width: 80px;
  }
}

a:not(.btn), .pagination li a {
  color: $link-color;
  text-underline: none;
  i:not(.shift) { color: #cccccc; }
  &:hover, &:active {
    i {
      color: $link-color-hover;
      &.danger { color: #ff0000; }
      &.success { color: green; }
    }
  }
}

.pagination .active {
  a, span, a:hover, span:hover, a:focus, span:focus {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
  }
}

.nav-tabs {
  li {
    a, i {
      color: rgb(125, 127, 127) !important;
    }
    &.active, &:hover {
      a, i {
        color: $link-color !important;
      }
    }
  }
}

.filter-bar {
  background: #eeeeee;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  p {
    display: inline;
    margin-right: 10px;
  }
  form {
    display: inline;
    .form-control {
      min-width: 10%;
    }
  }
}

.img-circle {
  border-radius: 10%;
}

#search-results {
  margin-top: 20px;
  .avatar{
    width: 25px;
    height: 25px;
    margin-top: -15px;
    margin-bottom: -15px;
  }
}

table {
  .actions {
    visibility: hidden;
    text-align: right;
    i {
      margin-left: 5px;
    }
  }
}

.vacancy-candidate-working-shift {
  border-left: 2px solid $brand-danger !important;
}

article.notification, article.communication{
  margin-top: 10px;
  border-bottom: 1px solid #cccccc;
  .date{
    color: #999999;
    line-height: 1.5em;
  }
}

//----------
tr{
  &.flash{
   background-color: #efefef;
  }
}
.flash{

  -moz-animation: flash 1s ease-out;
  -moz-animation-iteration-count: 2;

  -webkit-animation: flash 1s ease-out;
  -webkit-animation-iteration-count: 2;

  -ms-animation: flash 1s ease-out;
  -ms-animation-iteration-count: 2;

}

.shift-summary{
  color: #000000;
}

@-webkit-keyframes flash {
  100% {border: 1px solid $link-color-hover}
  50% {border: 1px solid $link-color}
  100% {border: 1px solid $link-color-hover}
}

@-moz-keyframes flash {
  100% {border: 1px solid $link-color-hover}
  50% {border: 1px solid $link-color}
  100% {border: 1px solid $link-color-hover}
}

@-ms-keyframes flash {
  100% {border: 1px solid $link-color-hover}
  50% { border: 1px solid $link-color}
  100% {border: 1px solid $link-color-hover}
}
//----------

tr{
  &.load-more{
    background-color: #f5f5f5;
  }
}
.load-more{
  margin-top: 20px;
  margin-bottom: 20px;
  .load-more-text{
    display: inline;
  }
  .loading-text{
    display: none;
  }
  &.loading{
    .load-more-text{
      display: none;
    }
    .btn{
      cursor: none;
    }
    .loading-text{
      display: inline;

    }
  }
}

.row {
  div.divider { margin: 20px 0; }
}

.filter-field.date-range input[readonly]:hover {
  cursor: pointer;
}

.no-horz-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-borders {
  border: 0 !important;
}

// helper classes
.pad-right {
  padding-right: 7px;
}

textarea {
  max-width: 100%;
}

// Marry-up flash message keys with the equivalent Bootstrap alerts.
.alert-notice { @extend .alert-success; }
.alert-alert { @extend .alert-warning; }
.alert-error { @extend .alert-danger; }
